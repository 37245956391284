import { useModals } from '@/compositions/modals'

type UseAllowedEmails = () => {
  openDeleteModal: (count: number) => void
  openNewModal: () => void
  openSettingsModal: () => void
}

const useAllowedEmails: UseAllowedEmails = () => {
  const { openModal } = useModals()

  const openDeleteModal = (count = 1) => {
    openModal('confirmation', {
      title: `Delete email address${count > 1 ? 'es' : ''}`,
      text: `You will no longer be able to send SMS from ${count > 1 ? 'these' : 'this'} email address${count > 1 ? 'es' : ''}. Are you sure you want to delete ${count > 1 ? 'these' : 'this'} email address${count > 1 ? 'es' : ''}?`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openNewModal = () => {
    openModal('allowedEmailsNew')
  }

  const openSettingsModal = () => {
    openModal('allowedEmailsSettings')
  }

  return {
    openDeleteModal,
    openNewModal,
    openSettingsModal,
  }
}

export default useAllowedEmails
