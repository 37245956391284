
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import AllowedEmailsDropdown from '@/components/pages/services/allowedEmails/AllowedEmailsDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmTable,
    AllowedEmailsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
  },
})
