
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useAllowedEmails from '@/compositions/services/useAllowedEmail'

export default defineComponent({
  components: {
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const { openDeleteModal, openNewModal } = useAllowedEmails()

    return {
      openDeleteModal,
      openNewModal,
    }
  },
})
