
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useAllowedEmails from '@/compositions/services/useAllowedEmail'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  setup() {
    const { openDeleteModal, openSettingsModal } = useAllowedEmails()

    return {
      openDeleteModal,
      openSettingsModal,
    }
  },
})
