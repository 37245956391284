import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_allowed_emails_dropdown = _resolveComponent("allowed-emails-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection
  }, {
    "body-cell-email-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_icon, {
          name: "email",
          size: "xLarge",
          class: "mr-2 distinct--text"
        }),
        _createElementVNode("span", null, _toDisplayString(props.row.email), 1)
      ])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_allowed_emails_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}