
import { computed, defineComponent, ref } from 'vue'
import AllowedEmailsFilter from '@/components/pages/services/allowedEmails/AllowedEmailsFilter.vue'
import AllowedEmailsTable from '@/components/pages/services/allowedEmails/AllowedEmailsTable.vue'
import AllowedEmailsEmpty from '@/components/pages/services/allowedEmails/AllowedEmailsEmpty.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { AllowedEmailType } from '@/definitions/services/allowedEmails/types'

export default defineComponent({
  name: 'ServicesAllowedEmails',
  components: {
    TmButton,
    AllowedEmailsTable,
    AllowedEmailsFilter,
    AllowedEmailsEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const selected = ref<AllowedEmailType[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Allowed emails', value: 'email-slot' },
      { text: 'Date added', value: 'dateAdded', format: (val: string) => formatDate(val) },
      { text: 'Last SMS', value: 'lastSms', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<AllowedEmailType[]>(getTableData('allowedEmails'))
    const filteredTableItems = computed<AllowedEmailType[]>(() => {
      return tableItems.value.filter((item) => item.email.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      filteredTableItems,
    }
  },
})
